// 1. Import the initial variables
@import 'node_modules/bulma/sass/utilities/initial-variables';
@import 'node_modules/bulma/sass/utilities/functions';

$control-height: 53px !default;

// // 2. Set your own initial variables
// // Update blue
// $blue: #72d0eb;
// // Add pink and its invert
// $pink: #ffb3b3;
// $pink-invert: #fff;
// // Add a serif family
// $family-serif: "Merriweather", "Georgia", serif;
//
// // 3. Set the derived variables
// // Use the new pink as the primary color
// $primary: $pink;
// $primary-invert: $pink-invert;
// // Use the existing orange as the danger color
// $danger: $orange;
// // Use the new serif family
// $family-primary: $family-serif;
//
// // 4. Setup your Custom Colors
// $linkedin: #0077b5;
// $linkedin-invert: findColorInvert($linkedin);
// $twitter: #55acee;
// $twitter-invert: findColorInvert($twitter);
// $github: #333;
// $github-invert: findColorInvert($github);
//
// // 5. Add new color variables to the color map.
// @import "../sass/utilities/derived-variables";
// $addColors: (
//   "twitter":($twitter, $twitter-invert),
//   "linkedin": ($linkedin, $linkedin-invert),
//   "github": ($github, $github-invert)
// );
// $colors: map-merge($colors, $addColors);
//
// // 6. Import the rest of Bulma
@import 'node_modules/bulma/bulma';

$slick-font-path: '~slick-carousel/slick/fonts/';
$slick-loader-path: '~slick-carousel/slick/';
@import 'node_modules/slick-carousel/slick/slick.scss';
@import 'node_modules/slick-carousel/slick/slick-theme.scss';

html,
body {
  font-family: 'Open Sans', sans-serif;
  color: $dark;
  height: 100%;
  width: 100%;
}

hr {
  background: #e9e9e9;
  height: 1px;
}

#root {
  height: 100%;
}

a.dashed {
  border-bottom: 1px dashed $blue;

  &:hover {
    border-bottom-color: $dark;
  }
}

.link {
  color: $link;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
  border: none;
  font-size: inherit;

  strong {
    color: currentColor;
  }

  &:hover {
    color: $link-hover;
  }

  &:focus {
    outline: none;
  }

  &.dashed {
    border-bottom: 1px dashed $link;

    &:hover {
      border-bottom-color: $link-hover;
    }
  }
}

.fullscreen {
  position: relative;
  z-index: 50;
}

.slick-arrow {
  z-index: 100;

  &.slick-next {
    right: 10px;
  }

  &.slick-prev {
    left: 10px;
  }

  &:before {
    color: $green;
    font-size: 40px;
  }
}

.slick-dots {
  bottom: -40px;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}
