$wrapperMaxWidth: 1240px;
$wrapperHPadding: 20px;
$blue: #4886ff;
$green: #2ed674;
$red: #ff9191;
$brightRed: #ff4646;
$yellow: #edd721;
$white: #fff;
$lightDark:#fafafa;
$dark: #171818;
$promoSectionTitleMarginBottom: 50px;
$promoSectionVerticalPadding: 90px;
$facebookColor: #3b5998;
$instagramColor: #bd299f;
$googleColor: #d74937;
$appleColor: #5a5a5a;
