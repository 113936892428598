@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.wrapper {
  padding-top: 50px;
}
.image {
  width: 300px;
  height: auto;
}

.h1 {
  font-size: 48px;
  font-weight: 800;
}

.h2 {
  font-size: 24px;
  font-weight: 500;
}

.rightColumn {
  padding-left: 30px;
}
.btn {
  margin-top: 30px;
  margin-bottom: 50px;
}
@media (min-width: 900px) {
  .rightColumn {
    padding-top: 50px;
  }
}