@import 'styles/variables.scss';

@mixin loader($borderWidth: 5px, $size: 30px, $color: $white) {
  &:after {
    content: '';
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: -9999em;
    transform: translateZ(0);
    animation: spin 1.1s infinite linear;
    border-top: $borderWidth solid rgba($color, 0.2);
    border-right: $borderWidth solid rgba($color, 0.2);
    border-bottom: $borderWidth solid rgba($color, 0.2);
    border-left: $borderWidth solid $color;
    width: $size;
    height: $size;
    left: 50%;
    margin-left: -$size/2;
    top: 50%;
    margin-top: -$size/2;
  }
}

@mixin link($color: $blue, $hoverColor: $dark, $textDecoration: none) {
  color: $blue;
  text-decoration: $textDecoration;

  &:hover, &:active {
    color: $hoverColor
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@mixin parentWithWrapper {
  padding-left: $wrapperHPadding;
  padding-right: $wrapperHPadding;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  .wrapper {
    position: relative;
    width: 100%;
    max-width: $wrapperMaxWidth;
  }
}
